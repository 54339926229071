import { createApp } from 'vue';
import appFrontEnd from './frontend/app.vue';
import urql from '@urql/vue';
import router from './frontend/router';
import { STATE_APP_KEY } from './frontend/providers/stateApp';
import './frontend/css/global.css';
//const stateApp= new StateApp();
createApp(appFrontEnd)
    .use(router)
    .use(urql, {
    url: 'https://api.thegraph.com/subgraphs/name/pumakawa/artistsland',
})
    .provide(STATE_APP_KEY, null)
    .mount('body');
